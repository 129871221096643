<template>
  <div class="row justify-center">
    <div class="col-sm-12 px-1 py-1">
      <v-expansion-panels tile flat accordion>
        <v-expansion-panel class="white--text rounded-lg">
          <v-expansion-panel-header
            class="white--text elevation-6 rounded-lg"
            color="#005c8a"
          >
            <span v-text="dayText"></span>
            <div class="mr-auto"></div>
            <span class="text-right mx-1">VER</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panels tile flat accordion class="py-3">
              <v-expansion-panel
                v-for="(event, i) in events"
                :key="i"
                class="black--text"
              >
                <v-expansion-panel-header class="black--text border-tb py-2">
                  <div class="d-flex flex-column">
                    <span
                      v-text="event.name"
                      class="font-weight-bold black--text my-1"
                    ></span>
                    <span v-text="event.rangeTime" class="my-1"></span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-expansion-panels accordion flat tile class="py-3">
                    <v-expansion-panel>
                      <div class="w-100 py-2 px-2">
                        <div
                          class="w-100 d-flex flex-column justify-left py-1"
                          :class="{
                            'border-bottom-1':
                              index % 2 == 0 && event.speakers.length > 1,
                          }"
                          v-for="(speaker, index) in event.speakers"
                          :key="index"
                        >
                          <span
                            v-text="speaker.name"
                            class="font-weight-bold black--text my-0"
                          ></span>
                          <span
                            v-text="speaker.companyName"
                            class="my-0"
                          ></span>
                        </div>
                      </div>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<style scoped>
.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: auto;
}

.v-expansion-panel > .v-expansion-panel-header.border-tb {
  border: solid 1px;
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
  border-color: #e9e9e9;
}

.border-bottom-1 {
  border: solid 1px;
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
  border-color: #e9e9e9;
}

.v-expansion-panel > .v-expansion-panel-header.elevation-6 {
  border-radius: 7px;
}

.v-expansion-panel-content__wrap {
  min-width: 100% !important;
  width: 100% !important;
}

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding-right: 0px !important;
  padding-bottom: 5px !important;
}

.v-expansion-panel.white--text >>> i {
  color: white !important;
}

.v-expansion-panel.black--text >>> i {
  color: black !important;
}
</style>

<script>
import moment from "moment";

export default {
  name: "AcademicEventDay",
  props: ["eventDay", "day"],
  data() {
    return {
      events: [],
    };
  },
  methods: {
    capitalizeFirstLetter(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
  },
  computed: {
    dayText() {
      let date = moment(this.day);
      return `${this.capitalizeFirstLetter(date.format("dddd"))}, ${date.format(
        "D"
      )} de ${this.capitalizeFirstLetter(date.format("MMMM"))}`;
    },
    totalEvents() {
      return this.events.length;
    },
  },
  created() {
    moment.locale("es");

    let initialHour, finalHour;
    this.eventDay.forEach((item) => {
      initialHour = moment(item.initialHour, "H:m:s").format("hh:mm A");
      finalHour = moment(item.finalHour, "H:m:s").format("hh:mm A");
      item.rangeTime = `${initialHour} - ${finalHour}`;
      this.events.push(item);
    });
  },
};
</script>
